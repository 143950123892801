.text-light {
    color: $white;
}

.text-dark {
    color: $black;
}

.text-alert {
    color: $hibiscus;
}

.text-gray-dark {
    color: $gray--dark;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-bold {
    font-weight: 600;
}

.text-semibold {
    font-weight: 500;
}

.text-regular {
    font-weight: 400;
}

.text-light {
    font-weight: 300;
}
