// --------------------------------------------------
// Navigation
// --------------------------------------------------

.nav-bar {
  background-color: $black;
  width: 100%;
  height: 56px;
  padding-top: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;

  &__content {
    font-family: 'TchigMono-Medium';
    position: relative;
    width: 94%;
    height: 56px;
    max-width: 1280px;
    margin: 0px auto;

    @include breakpoint(medium){
      width: 96%;
    }

    .cart {
      display: block;
      position: absolute;
      width: 15%;
      font-size: 14px;
      color: $white;
      top: 0px;
      right: 0px;

      img {
        position: absolute;
        right: 0px;
        top: 0px;
      }

      &__ammount {
        @include show-for(medium);

        display: block;
        margin-right: 46px;
        margin-top: 9px;
        position: absolute;
        top: 0px;
        right: 0px;
      }

      &__ammount-mobile {
        display: block;
        margin-right: 46px;
        margin-top: 9px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }

    .nav-menu {
      @include hide-for(large);

      display: block;
      position: absolute;
      width: 36px;
      height: 36px;
      top: 0px;
      left: 0px;
    }

    .nav-logo {
      width: auto;
      margin: 0px auto;
      display: block;
      font-family: 'TchigMono-Bold';
      color: $white;
      font-size: 16px;
      padding-top: 7px;
      text-align: center;

      @include breakpoint(large) {
        width: 15%;
        text-align: left;
        float: left;
      }

      a {
        color: $white;
        text-decoration: none;
        opacity: 1.0;
        transition: 0.2s all;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .nav-links {
      @include show-for(large);

      margin: 0px auto;
      width: 70%;
      font-family: 'TchigMono-Medium';
      font-size: 14px;
      color: $white;
      text-align: center;
      float: left;

      ul {
        margin-top: 9px;
        margin-left: 0px;
        text-align: center;

        li {
          display: inline-block;
          position: relative;
          margin-left: 4%;
          margin-right: 4%;

          a {
            color: $white;
            opacity: 1.0;

            &:hover {
              opacity: 0.8;
              transition: 0.2s all;
            }
          }
        }

      }
    }
  }
}

.mobile-nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $black;
  top: 0px;
  left: 0px;
  padding-top: 10px;
  overflow: scroll;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  transform: translateY(-20px);
  transition: all 0.3s;

  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 9999;
    transform: translateY(0px);
  }

  &__header {
    height: 56px;
  }

  &__close {
    padding-top: 0px;
    padding-left: 0px;
    float: left;
  }

  &__logo {
    width: 145px;
    height: 47px;
    margin: 0px auto;
    margin-top: 40px;
    margin-bottom: 48px;
  }

  .nav-links-mobile {

    margin: 0px auto;
    font-family: 'TchigMono-Medium';
    font-size: 16px;
    color: $white;
    text-align: center;

    ul {
      margin-top: 60px;
      margin-left: 0px;
      text-align: center;

      li {
        list-style-type: none;
        margin-bottom: 36px;

        a {
          color: $white;
          opacity: 1.0;

          &:hover {
            opacity: 0.8;
            transition: 0.2s all;
          }
        }
      }

    }
  }
}
