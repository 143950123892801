// --------------------------------------------------
// Config
// --------------------------------------------------

//
// Colors
// --------------------------------------------------

$black: #000000;
$white: #FFFFFF;
$gray--dark: #333333;
$gray: #777777;
$gray--light: #999999;
$gray--xlight: #D8D8D8;
$gray--xxlight: #EFEFEF;

$bg-default: #EFEFEF;
$text-default: #7A7A7A;
$text-light: #999999;
$button-default: #A4BA92;
$instagram-default: #A4BA92;

$tan: #E4CFC6;
$green: #D5DED0;
$pink: #E4C6C7;
$blue: #C9DAD6;
$orange: #EFD3C8;

$pale-purple: #9B9B9B;

$grass: #A4BA92;
$grass--dark: #73926A;
$grass--light: #CBD6C3;
$grass--bright: #82B28C;

$lit: #ABDFAA;

$hibiscus: #A00933;
