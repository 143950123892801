// --------------------------------------------------
// Footer
// --------------------------------------------------

footer {
  text-align: center;
  font-size: 12px;
  color: $text-light;
  margin-bottom: 64px;
  margin-top: 40px;
  position: relative;

  @include breakpoint(medium){
    margin-top: 60px;
    margin-bottom: 52px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
    background-image: url("/assets/img/icon--wave.svg");
    background-size: 36px 36px;
    background-position: center;
    background-repeat: no-repeat;
    top: 80px;
    left: 0px;
    margin-bottom: 10px;

    @include breakpoint(medium){
      top: 68px;
      margin-bottom: 0px;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
    background-image: url("/assets/img/icon--mountain.svg");
    background-size: 36px 36px;
    background-position: center;
    background-repeat: no-repeat;
    top: 80px;
    right: 0px;
    margin-bottom: 10px;

    @include breakpoint(medium){
      top: 68px;
      margin-bottom: 0px;
    }
  }

}
