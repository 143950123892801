// --------------------------------------------------
// Navigation
// --------------------------------------------------

.banner-alert {
  background-color: $white;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 700;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.05);

  &__content {
    font-family: 'TchigMono-Medium';
    position: relative;
    width: 94%;
    max-width: 1280px;
    margin: 0px auto;

    @include breakpoint(medium){
      width: 96%;
    }

    &__text {
      margin: 0px auto;
      width: 100%;
      font-family: 'Work Sans', sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: $grass--dark;
      text-align: center;
      float: left;
    }
  }
}
